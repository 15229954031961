<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <validation-observer ref="simpleRules">
      <div>
        <!-- <this-header /> -->
        <div class="anan-set-nav-conttainer4 mt-150">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12">
              <side-bar class="d-none d-md-block" />
            </div>
            <div class="col-lg-10 col-md-10 col-sm-12">
              <h3>{{ $t("manageMyAccount") }}</h3>

              <!-- <h3>   {{ $t("follow") }}</h3> -->
              <div class="row">
                <div class="col-md-8">
                  <div class="card h-100 overflow-hidden">
                    <div class="card-img-overlay d-block d-lg-none bg-blue rounded" />
                    <div
                      style="
                      background-image: url('/icon/wave-bg.png') !important;
                      background-position: right bottom;
                      background-repeat: no-repeat;
                      background-size: 100%;
                    "
                      class="card-img-overlay bg-blue rounded mb-n1 mx-n1"
                    />
                    <div class="card-img-overlay d-none d-md-block bottom-0 top-auto">
                      <div class="row">
                        <div class="col-md-8 col-xl-6" />
                        <div class="col-md-4 col-xl-6 mb-n4">
                          <img
                            src="/icon/dashboard.svg"
                            alt=""
                            class="d-block ms-n3 mb-n1 mr-n2"
                            style="max-height: 310px"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="card-body position-relative text-white text-opacity-70">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="d-flex">
                            <div class="me-auto">
                              <h5 class="text-white text-opacity-80 mb-2">
                                Welcome back,
                              </h5>
                              <h3 class="text-white mt-n1 mb-50">
                                {{ $t('greeting') }}, {{ username.fullname }}
                              </h3>
                            </div>
                          </div>
                          <hr class="bg-white bg-opacity-75 mt-1 mb-1">
                          <div class="row">
                            <div class="col-6 col-lg-5">
                              <div>฿</div>
                              <div>
                                <div>{{ $t("systemMoney") }}</div>
                                <div class="fw-600 text-white">
                                  <span class="font-weight-bolder h3 text-white">{{ Commas(Balance.balance) }}</span>
                                  {{ $t("baht") }}
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-6 col-lg-5">
                            <div>
                              ¥
                            </div>
                            <div>
                              <div>เงินหยวนในระบบ</div>
                              <div class="fw-600 text-white">
                                <span class="font-weight-bolder h3 text-white">700.00</span> หยวน
                              </div>
                            </div>
                          </div> -->
                          </div>
                          <hr class="bg-white bg-opacity-75 mt-2 mb-1">
                          <div class="mt-2 mb-2">
                            <button
                              class="btn-yellow btn-rounded"
                              @click="$router.push({ name: 'balance' })"
                            >
                              <i class="fa fa-wallet mr-50" />
                              {{ $t("topupSystem") }}
                            </button>
                          </div>
                          <div class="mt-2">
                            <small class="fs-12px">
                              {{ $t("allShippingChinaToThailand") }}
                              {{ items.point }} ฿ {{ $t("earnPoints") }} 1 Point
                              <br>
                              {{ $t("pointsDiscountConversion") }}
                            </small>
                            <div class="mt-3 d-md-none d-md-hidden" />
                          </div>
                        </div>
                        <!-- <div
                        class="col-md-4 d-none d-md-block"
                        style="min-height: 380px"
                      /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mt-1">
                  <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-ware">
                    <div
                      class="card-img-overlay mb-n2 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/icon/ware.png"
                        alt=""
                        class="ms-auto d-block mt-n2"
                        style="max-height: 50px"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h5 class="text-white text-opacity-80 mb-50 fs-16px">
                        {{ $t("addr_chi") }}
                      </h5>
                      <!-- <h3 class="text-white font-weight-bolder">
                        {{ UserData.username }} ทางรถ
                      </h3> -->
                      <small
                        style="font-size: 12px"
                        class="text-white"
                      >{{ items.chi_warehouse_address }}</small>
                    </div>
                  </div>

                  <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-indigo">
                    <div
                      class="card-img-overlay mb-n2 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/icon/car.svg"
                        alt=""
                        class="ms-auto d-block mt-n2"
                        style="max-height: 50px"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h5 class="text-white text-opacity-80 mb-50 fs-16px">
                        {{ $t("truckCode") }}
                      </h5>
                      <h3 class="text-white font-weight-bolder">
                        {{ UserData.username }} ทางรถ
                      </h3>
                      <!-- <small style="font-size: 12px">{{ items.chi_warehouse_address }}</small> -->
                    </div>
                  </div>

                  <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-pink">
                    <div
                      class="card-img-overlay mb-n1 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/icon/ship.svg"
                        alt=""
                        class="ms-auto d-block mb-n25"
                        style="max-height: 40px"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h5 class="text-white text-opacity-80 mb-50 fs-16px">
                        {{ $t("shipCode") }}
                      </h5>
                      <h3 class="text-white font-weight-bolder">
                        {{ UserData.username }}(SEA) ทางเรือ
                      </h3>
                      <!-- <small style="font-size: 12px">{{ items.chi_warehouse_address }}</small> -->
                    </div>
                  </div>

                  <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-orange">
                    <div
                      class="card-img-overlay mb-n3 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/icon/order.svg"
                        alt=""
                        class="ms-auto d-block mb-n25"
                        style="max-height: 80px"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h5 class="text-white text-opacity-80 mb-50 fs-16px">
                        {{ $t('key-186') }} <small>({{ $t('key-187') }})</small>
                      </h5>
                      <h6 class="text-white">
                        {{ UserData.username }} (打木架)
                      </h6>
                      <h5 class="text-white mb-50 fs-16px">
                        {{ $t('qc-2') }}
                        <small>({{ $t('key-187') }})</small>
                      </h5>
                      <h6 class="text-white">
                        {{ UserData.username }}(QC)
                      </h6>
                    </div>
                  </div>

                </div>
              </div>

              <b-row class="mt-1">
                <b-col
                  cols="12"
                  lg="8"
                  md="8"
                  sm="12"
                  order="2"
                  order-md="1"
                  order-lg="1"
                  order-sm="2"
                >
                  <div class="card overflow-hidden fs-13px border-0 bg-gradients-custom-info">
                    <div
                      class="card-img-overlay mb-n3 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/icon/importProduct.png"
                        alt=""
                        class="ms-auto d-block mb-n25"
                        style="max-height: 120px"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h4 class="font-weight-bolder">
                        {{ $t('key-188') }}
                      </h4>

                      <div class="row">
                        <div class="col-12">
                          <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-1">

                            <div class="card-body position-relative">
                              <h2 class="text-dark text-center text-opacity-80 mb-50 fs-16px">
                                <img
                                  src="/icon/car.svg"
                                  style="max-height: 30px"
                                > {{ $t('truck') }}
                              </h2>
                              <table
                                border="1"
                                class="small font-weight-bolder table-custom-2"
                                width="100%"
                              >
                                <thead>
                                  <tr
                                    align="center"
                                    class="bg-dark text-white"
                                  >
                                    <td rowspan="2" />
                                    <td>KG ({{ $t('kilo') }})</td>
                                    <td>CBM ({{ $t('key-18') }})</td>
                                  </tr>
                                </thead>
                                <tbody align="center">
                                  <tr
                                    v-for="(item, index) in producttype"
                                    :key="index"
                                  >
                                    <td width="100px">
                                      {{ item.name }}
                                    </td>
                                    <td>{{ Commas(checkproduct(item.name, 1, 1)) }}</td>
                                    <td>{{ Commas(checkproduct(item.name, 1, 2)) }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>

                        <div class="col-12">
                          <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-2">
                            <div class="card-body position-relative">
                              <h2 class="text-dark text-center text-opacity-80 mb-50 fs-16px">
                                <img
                                  src="/icon/ship.svg"
                                  style="max-height: 30px"
                                > {{ $t('ship') }}
                              </h2>
                              <table
                                border="1"
                                class="small font-weight-bolder table-custom-1"
                                width="100%"
                              >
                                <thead>

                                  <tr
                                    align="center"
                                    class="bg-white text-dark"
                                  >
                                    <td />
                                    <td>KG ({{ $t('kilo') }})</td>
                                    <td>CBM ({{ $t('key-18') }})</td>
                                  </tr>
                                </thead>
                                <tbody align="center">
                                  <tr
                                    v-for="(item, index) in producttype"
                                    :key="index"
                                  >
                                    <td width="100px">
                                      {{ item.name }}
                                    </td>
                                    <td>{{ Commas(checkproduct(item.name, 2, 1)) }}</td>
                                    <td>{{ Commas(checkproduct(item.name, 2, 2)) }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <table
                      border="1"
                      class="small"
                      width="100%"
                    >
                      <thead>
                        <tr
                          align="center"
                          class="font-weight-bolder"
                        >
                          <td rowspan="2">
                            ประเภทสินค้า
                          </td>
                          <td colspan="2">
                            รถ
                          </td>
                          <td colspan="2">
                            เรือ
                          </td>
                        </tr>
                        <tr
                          align="center"
                          class="bg-secondary text-white font-weight-bolder"
                        >
                          <td>KG (กก)</td>
                          <td>CBM (คิว)</td>
                          <td>KG (กก)</td>
                          <td>CBM (คิว)</td>
                        </tr>
                      </thead>
                      <tbody align="center">
                        <tr
                          v-for="(item, index) in producttype"
                          :key="index"
                        >
                          <td width="100px">
                            {{ item.name }}
                          </td>
                          <td>{{ Commas(checkproduct(item.name, 1, 1)) }}</td>
                          <td>{{ Commas(checkproduct(item.name, 1, 2)) }}</td>
                          <td>{{ Commas(checkproduct(item.name, 2, 1)) }}</td>
                          <td>{{ Commas(checkproduct(item.name, 2, 2)) }}</td>
                        </tr>
                      </tbody>
                    </table> -->
                      <h4 class="mt-2 font-weight-bolder">
                        {{ $t('key-189') }}
                      </h4>
                      <table class="table table-sm">
                        <tbody>
                          <tr
                            v-for="(item, index) in producttype"
                            :key="index"
                          >
                            <td
                              width="25%"
                              align="center"
                            >
                              {{ item.name }}
                              <!-- ธรรมดา (A) -->
                            </td>
                            <td>{{ item.detail }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="12"
                  order="1"
                  order-md="2"
                  order-lg="2"
                  order-sm="1"
                >

                  <!-- <div class="card overflow-hidden fs-13px border-0 bg-gradient-custom-green">
                    <div
                      class="card-img-overlay mb-n3 d-flex"
                      style="bottom: 0px; top: auto"
                    >
                      <img
                        src="/referral.webp"
                        alt=""
                        class="ms-auto d-block"
                        style="max-height: 150px;margin-right: -30px;"
                      >
                    </div>
                    <div class="card-body position-relative">
                      <h3
                        v-if="invite_all.invite"
                        class="mb-50 text-dark d-flex align-items-center"
                      >
                        <strong>
                          รหัสสำหรับแนะนำ
                        </strong>
                      </h3>
                      <h3
                        v-else
                        class="mb-50 text-dark d-flex align-items-center"
                      >
                        <strong>
                          {{ $t('key-237') }}
                        </strong>
                        <feather-icon
                          icon="SettingsIcon"
                          class="text-bolder cursor-pointer"
                          size="18"
                          @click="ReferralModal"
                        />
                      </h3>
                      <strong class="text-bolder">{{ invite_all.invite ? invite_all.invite_code: invite_all.invited_by }}</strong>
                      <span
                        v-if="invite_all.invite || invite_all.invited_by"
                        class="cursor-pointer bg-copy"
                        @click="copyReferral"
                      >
                        <feather-icon icon="CopyIcon" /> Copy
                      </span>
                    </div>
                  </div> -->

                  <div class="card p-2">
                    <h4 class="mb-0">
                      {{ $t("notificationStatus") }}
                    </h4>

                    <b-row class="row match-height">
                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">
                            <img
                              src="/icon/pay.svg"
                              alt="wait-payments"
                              @click="$router.push({ name: 'order-list' })"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t("awaitingPayment") }}
                            <span class="text-danger">({{
                              Checkitem.statuspay ? Checkitem.statuspay : "0"
                            }})</span>
                          </p>
                        </div>
                      </b-col>

                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">
                            <img
                              src="/icon/tran.svg"
                              alt="wait-transport"
                              @click="$router.push({ name: 'payment-create' })"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t("awaitingDelivery") }}
                            <span class="text-danger">({{
                              Checkitem.statuspost ? Checkitem.statuspost : "0"
                            }})</span>
                          </p>
                        </div>
                      </b-col>

                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">
                            <img
                              src="/icon/sucess.svg"
                              alt="success"
                              @click="$router.push({ name: 'export' })"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t("delivered") }}
                            <span class="text-danger">({{
                              Checkitem.exports ? Checkitem.exports : "0"
                            }})</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="card p-2">
                    <h4 class="mb-0">
                      {{ $t("details") }}
                    </h4>
                    <div class="mt-2">
                      <p>
                        {{ $t("calculateCratePricing") }} :
                        {{ items.cal_woodencrate }}
                      </p>
                      <p>
                        {{ $t("chinaWarehouseAddress") }} :
                        {{ items.chi_warehouse_address }}
                      </p>
                      <p>
                        {{ $t("chinaWarehouseZip") }} :
                        {{ items.chi_warehouse_zipcode }}
                      </p>
                      <p>
                        {{ $t("chinaWarehousePhone") }} :
                        {{ items.chi_warehouse_tel }}
                      </p>
                      <p class="mb-0">
                        {{ $t("thailandWarehousePhone") }} :
                        {{
                          items.thai_warehouse_tel
                            ? items.thai_warehouse_tel
                            : "-"
                        }}
                      </p>
                    </div>
                    <!-- <div
                    v-if="click == 2"
                    class="mt-2"
                  >
                    <b-row class="row match-height">
                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">

                            <img
                              src="/icon/pre-order.png"
                              alt="wait-payments"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t('preOrderRate') }} <span class="text-danger">({{ Commas(items.rate_preorder) }} ฿)</span>
                          </p>
                        </div>
                      </b-col>

                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">
                            <img
                              src="/icon/import.png"
                              alt="wait-transport"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t('importProductRate') }} <span class="text-danger">({{ Commas(items.rate_import) }} ฿)</span>
                          </p>
                        </div>
                      </b-col>

                      <b-col
                        cols="4"
                        class="col m-0 p-0"
                      >
                        <div class="text-center">
                          <div class="card-img-tran mt-1">
                            <img
                              src="/icon/payment.png"
                              alt="success"
                            >
                          </div>
                          <p class="mb-0 mt-50">
                            {{ $t('topUpRate') }} <span class="text-danger">({{ Commas(items.rate_payment) }} ฿)</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row> -->

                    <!-- <p>เรทพรีออเดอร์ : {{ Commas(items.rate_preorder) }} ฿</p>
                    <p>เรทนำเข้าสินค้า : {{ Commas(items.rate_import) }} ฿</p>
                    <p class="mb-0">
                      เรทเติมเงิน : {{ Commas(items.rate_payment) }} ฿
                    </p> -->
                    <!-- </div> -->
                  </div>
                </b-col>
              </b-row>

              <!-- <div class="dashboard">
              <div class="dashboard-profile">
                <div class="dashboard-mod-title">
                  ข้อมูลส่วนตัว <span>|</span>
                  <a
                    data-spm="dprofile_edit"
                    href="#/profile/edit"
                  >แก้ไข</a>
                </div>
                <div class="dashboard-info">
                  <div class="dashboard-info-item">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-info-item">
                    lomcho***@gmail.com
                  </div>
                  <div class="dashboard-info-item dashboard-info-item-check">
                    <div class="dashboard-info-item-check-sub my-50">
                      <b-form-checkbox
                        v-model="selected"
                        value="A"
                      >
                        <small>รับการแจ้งเตือน ผ่านทางอีเมล</small>
                      </b-form-checkbox>
                    </div>

                    <div class="dashboard-info-item-check-sub">
                      <b-form-checkbox
                        v-model="selected"
                        value="A"
                      >
                        <small>รับการแจ้งเตือนทาง ผ่านทาง Line</small>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-address">
                <div class="dashboard-address-item shipping">
                  <div class="dashboard-mod-title">
                    สมุดที่อยู่ <span>|</span>
                    <a
                      href="/address#/book"
                      data-spm="dprofile_edit"
                    >แก้ไข</a>
                  </div>
                  <div class="dashboard-address-default">
                    ที่อยู่เริ่มต้นในการจัดส่งสินค้า
                  </div>
                  <div class="dashboard-address-username">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-address-detail">
                    12/345 */ ม.10 ศุภาลัย วิลล์ วงแหวน-รัตนาธิเบต ต.บางแม่นาง
                  </div>
                  <div class="dashboard-address-detail">
                    นนทบุรี/ Nonthaburi - บางใหญ่/ Bang Yai - 11140
                  </div>
                  <div class="dashboard-address-phone">
                    (+66) 0659356829
                  </div>
                </div>
                <div class="dashboard-address-item billing">
                  <div class="dashboard-mod-title">
                    สมุดที่อยู่ <span>|</span>
                    <a
                      data-spm="dprofile_edit"
                      href="/address#/book"
                    >แก้ไข</a>
                  </div>
                  <div class="dashboard-address-default">
                    ที่อยู่เริ่มต้นในการออกใบกำกับภาษี
                  </div>
                  <div class="dashboard-address-username">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-address-detail">
                    12/345 ม.10 ศุภาลัย วิลล์ วงแหวน-รัตนาธิเบต ต.บางแม่นาง
                  </div>
                  <div class="dashboard-address-detail">
                    นนทบุรี/ Nonthaburi - บางใหญ่/ Bang Yai - 11140
                  </div>
                  <div class="dashboard-address-phone">
                    (+66) 0659356829
                  </div>
                </div>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-Referral"
        ok-title="ยืนยัน"
        cancel-title="ยกเลิก"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        centered
        title="ตั้งรหัสผู้แนะนำ"
        @ok.prevent="Submitinvite()"
      >
        <b-card-text>
          <validation-provider
            #default="{ errors }"
            name="invite"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                {{ $t('key-237') }}
              </b-input-group-prepend>

              <b-form-input
                v-model="invited_by"
                placeholder="รหัสผู้แนะนำ"
                :state="errors.length > 0 ? false : null"
              />

            </b-input-group>
            <small class="text-danger">{{ errors[0] ? 'กรุณากรอกโค้ดผู้แนะนำ': '' }}</small>
          </validation-provider>
        </b-card-text>
      </b-modal>

      <b-modal
        id="add-addr"
        :title="$t('addNewAddress')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="addAddr()"
      >
        <validation-observer ref="addRules">
          <b-form-group
            label-for="h-name"
            :label="$t('key-77')"
          >
            <validation-provider
              #default="{ errors }"
              name="Full Name"
              rules="required"
            >
              <b-form-input
                id="h-name"
                v-model="fullName"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-phone-number"
            :label="$t('key-146')"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone Number"
              rules="required"
            >
              <b-form-input
                id="h-phone-number"
                v-model="phoneNumber"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-address"
            :label="$t('addressNumber')"
          >
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-textarea
                id="h-address"
                v-model="address"
                row="2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-province-name"
            :label="$t('province')"
          >
            <validation-provider
              #default="{ errors }"
              name="Province"
              rules="required"
            >
              <b-form-input
                id="h-province-name"
                v-model="province"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-district-name"
            :label="$t('district')"
          >
            <validation-provider
              #default="{ errors }"
              name="District"
              rules="required"
            >
              <b-form-input
                id="h-district-name"
                v-model="district"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-sub-district-name"
            :label="$t('subdistrict')"
          >
            <validation-provider
              #default="{ errors }"
              name="Sub District"
              rules="required"
            >
              <b-form-input
                id="h-sub-district-name"
                v-model="subDistrict"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-postal-code"
            :label="$t('postalCode')"
          >
            <validation-provider
              #default="{ errors }"
              name="Postal Code"
              rules="required"
            >
              <b-form-input
                id="h-postal-code"
                v-model="postalCode"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BTable,
  BTh,
  BTr,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SideBar from './component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    BRow,
    BCol,
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BModal,
    // BFormCheckbox,
    SideBar,
    BTable,
    BTr,
    BTh,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      click: 1,
      required,
      email,
      ReferralCode: 'ANAN20241ES',
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],
      items: [],
      Balance: [],
      username: [],
      Checkitem: [],
      showOver: true,
      UserData: JSON.parse(localStorage.getItem('userData')),
      locale: this.$i18n.locale,
      // announceFields: [
      //   { key: 'type', label: 'ประเภทสินค้า' },
      //   { key: 'car', {key: 'kg', label: 'KG'} },
      //   { key: 'chip', label: 'เรือ' },
      // ],
      announce: [
        {
          type: `${this.$t('key-190')} (A)`,
          car: { kg: 15, cbm: 4500 },
          chip: { kg: 10, cbm: 4500 },
        },
        {
          type: `${this.$t('key-191')} (B)`,
          car: { kg: 25, cbm: 6500 },
          chip: { kg: 10, cbm: 4500 },
        },
        {
          type: `${this.$t('key-192')} (C)`,
          car: { kg: 35, cbm: 7000 },
          chip: { kg: 30, cbm: 6000 },
        },
        {
          type: `${this.$t('key-193')} (D)`,
          car: { kg: 45, cbm: 9000 },
          chip: { kg: 40, cbm: 9000 },
        },
        {
          type: `${this.$t('key-194')} (E)`,
          car: { kg: 55, cbm: 12000 },
          chip: { kg: 50, cbm: 12000 },
        },
      ],
      producttype: [],
      productuser: null,
      producttypeshow: [],
      invited_by: null,
      invite_all: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val
    },
  },
  mounted() {
    // this.getPreProduct()
    this.Getgeneral()
    this.GetOrderAll()
    this.getName()
    this.getBalance()
    this.Getproduct_type()
    this.Groupbyuser()
    this.GetdataUser()
  },
  methods: {
    ReferralModal() {
      this.$bvModal.show('modal-Referral')
    },
    async GetdataUser() {
      this.$http.get('/users/GetUser')
        .then(response => {
          if (response.data) {
            this.invite_all = response.data
          }
        })
    },
    copyReferral() {
      if (this.invite_all.invite) {
        navigator.clipboard.writeText(this.invite_all.invite_code)
      } else {
        navigator.clipboard.writeText(this.invite_all.invited_by)
      }
      this.$bvToast.toast('Copy รหัสผู้แนะนำเรียบร้อย', {
        title: this.$t('key-280'),
        variant: 'success',
        solid: true,
        autoHideDelay: 1500,
      })
    },
    async getPreProduct() {
      const { data } = await this.$http.get('/v1/product/pre-product')
      this.info = data
      this.ShowProduct = false
    },
    Submitinvite() {
      if (this.invited_by) {
        const obj = {
          invited_by: this.invited_by,
        }
        this.$http.post('/users/UpDateInvite', obj)
          .then(response => {
            if (response.data.status) {
              this.GetdataUser()
              this.Success(response.data.message)
              this.$bvModal.hide('modal-Referral')
              this.invited_by = null
            }
          })
          .catch(err => {
            console.log(err.response.data.message)
            this.SwalError(err.response.data.message)
            this.invited_by = null
          })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    async getName() {
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/showcus')
        this.username = res
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    async Getgeneral() {
      try {
        const { data: res } = await this.$http.get('/general/getData')
        if (res.status) {
          this.items = res.data
          console.debug(this.items)
        }
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    async Groupbyuser() {
      try {
        const { data: res } = await this.$http.get('/groupbyuser/show')
        if (res.success) {
          this.productuser = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async Getproduct_type() {
      try {
        const { data: res } = await this.$http.get('/product_type/showlist')
        if (res.status) {
          const uniqueNamesMap = new Map()
          const itemsarr = []
          // eslint-disable-next-line no-restricted-syntax
          for (const item of res.data) {
            if (item.name && !uniqueNamesMap.has(item.name)) {
              const obj = {
                name: item.name,
                detail: item.detail,
              }
              itemsarr.unshift(obj)
              uniqueNamesMap.set(item.name, item.detail)
            }
          }
          this.producttype = itemsarr
          this.producttypeshow = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    // eslint-disable-next-line consistent-return
    checkproduct(item, type, kgCBM) {
      if (item) {
        if (this.productuser) {
          // eslint-disable-next-line no-restricted-syntax
          for (const data of this.productuser.product_type) {
            if (
              data.product_type_name === item
              && data.product_type_class === type
            ) {
              if (kgCBM === 1) {
                return data.product_type_kilo
              }
              if (kgCBM === 2) {
                return data.product_type_queue
              }
            }
          }
        }
      } else {
        return 0
      }
      // console.log(this.productuser)
    },
    //
    async getBalance() {
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        this.Balance = res
        // console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    async GetOrderAll() {
      try {
        const { data: res } = await this.$http.get('/order/getCheckstatus')
        this.Checkitem = res
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.bg-gradient-custom-info {
  background-image: linear-gradient(120deg, #c9deff 0%, #ecf9ff 100%);
}

.bg-gradient-custom-1{
background-image: linear-gradient(to left top, #ffbb97, #fecea2, #fcdfb3, #fcf0c7, #feffdd);
box-shadow: 3px 3px 3px rgb(0, 0, 0,.3) !important;
border: solid 2px rgb(255, 255, 255,.5) !important;
}

.bg-gradient-custom-2{
background-image: linear-gradient(to left top, #50dec5, #77e7d8, #9aefe8, #bcf7f5, #ddfeff);
box-shadow: 3px 3px 3px rgb(0, 0, 0,.3) !important;
border: solid 2px rgb(255, 255, 255,.5) !important;
}

.table-custom-1{
  border-radius: 50% !important;
  border: 2px dotted rgb(255, 255, 255,.5) !important;
}

.table-custom-2{
  border-radius: 50% !important;
  border: 2px dotted rgb(0, 0, 0,.2) !important;
}

@media only screen and (max-width: 600px) {
  /* .bg-blue{
  color: black !;
} */
}

.bg-copy{
  border-radius: 10px;
  background-color: rgb(201, 84, 80);
  color: white;
  padding: 5px;
  font-weight: 900;
  font-size: 9px;
  box-shadow: 2px 2px black;
}
</style>
